import { LinksFunction, LoaderFunctionArgs, json } from '@remix-run/node';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { useGoogleAnalytics } from '~/lib/useGoogleAnalytics';
import { getUserFromRequest } from '~/session.server';

import stylesheet from '~/tailwind.css?url';

// export const links: LinksFunction = () => [
//   { rel: 'stylesheet', href: stylesheet },
//   ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
// ];

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: stylesheet }];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return json({ user: await getUserFromRequest(request) });
};

/**
 * Root Layout
 */
export function Layout({ children }: { children: React.ReactNode }) {
  useGoogleAnalytics();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />

        <script src="https://cdn.amplitude.com/libs/analytics-browser-2.7.4-min.js.gz"></script>
        <script src="https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.4.1-min.js.gz"></script>
        <script src="https://cdn.amplitude.com/libs/plugin-autocapture-browser-0.9.0-min.js.gz"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.amplitude.add(window.sessionReplay.plugin({sampleRate: 1})).promise.then(function() {window.amplitude.add(window.amplitudeAutocapturePlugin.plugin());window.amplitude.init('a96621ec522e674e251263e62af5777f');});`,
          }}
        />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  console.error(error);

  return (
    <Layout>
      <div className="text-center">
        <h1 className="text-4xl font-bold text-red-500">An error occurred</h1>
        <p className="text-lg text-gray-500">Please try again later.</p>
      </div>
    </Layout>
  );
};

export default function App() {
  return <Outlet />;
}
