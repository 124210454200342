'use client';

import { useLocation } from '@remix-run/react';
import { useEffect } from 'react';
import { gtag } from '~/lib/gtag.client';

export function useGoogleAnalytics() {
  const gaTrackingId = import.meta.env.VITE_GA_TRACKING_ID;

  const location = useLocation();

  useEffect(() => {
    if (import.meta.env.DEV) {
      return;
    }

    if (!gaTrackingId) {
      console.warn('GA Tracking ID is not defined');
      return;
    }

    if (document.getElementById('gtag-script')) {
      return;
    }
    const script1 = document.createElement('script');
    script1.id = 'gtag-script';
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
    script1.async = true;
    document.head.appendChild(script1);

    if (document.getElementById('gtag-init')) {
      return;
    }
    const script2 = document.createElement('script');
    script2.async = true;
    script2.id = 'gtag-init';
    script2.innerHTML = `window.dataLayer = window.dataLayer || [];
                function gtag(){ dataLayer.push(arguments); };
                gtag('js', new Date());
                gtag('config', '${gaTrackingId}', { page_path: window.location.pathname });`;
    script2.async = true;
    document.head.appendChild(script2);
  }, []);

  useEffect(() => {
    gtag.pageview(location.pathname, gaTrackingId);
  }, [location]);
}
